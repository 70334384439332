/*
    Utility to create new date with a specified number of minutes offset
  */
export const getNMinutesFromNowUTC = function (minutes) {
  const now = new Date()
  return new Date(now.getTime() + minutes * 60000)
}

export const getNowUTC = () => {
  return (new Date()).toISOString()
}

/*
    Validate that the format of the effective date time is correct before sending it as a date
  */
export const validateEffectiveDateTimeFormat = function (effectiveDateTime) {
  /* eslint-disable no-useless-escape */
  // Source: https://regex101.com/r/ufFBTA/2
  const dateTimeRegexp =
      /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\-)(?:\d{2}):?(?:\d{2}))/

  return dateTimeRegexp.test(effectiveDateTime)
}
