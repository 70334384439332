export const COOKIE_NAME = 'PCXT'
export const COOKIE_REGEX = new RegExp(`(^| )${COOKIE_NAME}=([^;]+)`)

export const SCRIPT_LOADED_EVENT = 'PCXT-V3-ScriptLoaded'
export const INITIALIZED_EVENT = 'PCXT-V3-ModuleInitialized'
export const CONTEXT_CHANGE_EVENT = 'PCXT-V3-ContextChanged'
export const COUPON_CHANGE_EVENT = 'PCXT-V3-CouponCodeChanged'
export const VAT_INCLUSIVITY_CHANGE_EVENT = 'PCXT-V3-VatInclusivityChanged'
export const DATE_TIME_CHANGE_EVENT = 'PCXT-V3-EffectiveDateTimeChanged'

export const VAT_INCLUSIVITY_QUERY_PARAMETER = 'PCXTVATINCLUSIVE'
export const DATE_TIME_QUERY_PARAMETER = 'PCXTEFFECTIVEDATETIME'
