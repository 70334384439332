/*
    Emit an event from our module that can be intercepted by the page
  */

import { CONTEXT_CHANGE_EVENT, COUPON_CHANGE_EVENT, DATE_TIME_CHANGE_EVENT, INITIALIZED_EVENT, SCRIPT_LOADED_EVENT, VAT_INCLUSIVITY_CHANGE_EVENT } from '../shared/constants'

export const fireEvent = function (eventName: string, payload: any) {
  if (typeof document === 'undefined') {
    return
  }

  const event = new CustomEvent(eventName, { detail: payload })
  document.dispatchEvent(event)
}

export const EVENTS = {
  SCRIPT_LOADED_EVENT,
  INITIALIZED_EVENT,
  CONTEXT_CHANGE_EVENT,
  COUPON_CHANGE_EVENT,
  VAT_INCLUSIVITY_CHANGE_EVENT,
  DATE_TIME_CHANGE_EVENT,
}
