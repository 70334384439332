function timeout (milliseconds: number) : Promise<Response> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out.'))
    }, milliseconds)
  })
}
/*
    Get a resource, with options (options.timeout required)
  */
type FetchWithTimeoutInput = string | URL | globalThis.Request
type FetchWithTimeoutOptions = RequestInit & { timeout: number }

export function fetchWithTimeout (resource: FetchWithTimeoutInput, options: FetchWithTimeoutOptions) {
  const controller = new AbortController()

  return Promise.race<Response>([
    fetch(resource, {
      ...options,
      signal: controller.signal,
    }),
    timeout(options.timeout),
  ]).catch(error => {
    if (error.message === 'Request timed out.') {
      controller.abort()
    }

    return new Response(null, { status: 408, statusText: 'Request timed out.' })
  })
}
