import VistaprintAuth from '@vp/auth'
import { ShopperInfo } from '../shared/types'

export const getShopperInfo = () : ShopperInfo => {
  try {
    const auth = new (VistaprintAuth.WebAuth ?? VistaprintAuth.default.WebAuth)()
    return {
      authorization: auth.getAuthorizationHeader(),
      shopperId: auth.getCanonicalId(),
    }
  } catch (ex) {
    return {}
  }
}

export const isValidShopperInfo = function (shopperInfo) {
  return !!(shopperInfo && shopperInfo.shopperId && shopperInfo.authorization)
}
