import {
  getCurrentContext, getContextString, getContextQueryString, setEffectiveDateTime,
  setCoupon, setVatInclusivity, init, clearDataOnOrderPlaced, isInitialized,
  backgroundRefresh, reset,
  SetCouponResponse
} from './context'
import { isDefaultTaxInclusive } from './vat'
import { isValidShopperInfo, getShopperInfo } from './shopper'
import { fireEvent } from './events'
import { SCRIPT_LOADED_EVENT } from '../shared/constants'
export * from './helpers'
export { EVENTS } from './events'

// Create the base pricingContext object, to be exported globally
const contextModule = {

  // Set the version of this object. Used when introducing new changes to the context module.
  // PCM_CURRENT_VERSION string will be replaced with the bumped version before publishing.
  version: '@PCM_CURRENT_VERSION@',

  /*
    Gets the pricing context from the user's cookie. Returns the default
    context for the current site if no cookie is set.
  */
  getPricingContext: getCurrentContext,

  /*
    Gets the base 64 encoded pricing context string from the user's cookie. Returns the default
    context for the current site if no cookie is set.
  */
  getEncodedContextString: getContextString,

  /*
    Gets the current pricing context as a query string that can be used for Product Pricing Service GET requests.

    @param {Boolean} encodeUri - Whether to encode the resulting uri.
  */
  getContextAsQueryString: getContextQueryString,

  /*
    Sets an EffectiveDateTime on the user's pricing context cookie.
    Needs to follow ISO 8601 format, see https://en.wikipedia.org/wiki/ISO_8601

    Format: {YYYY}-{MM}-{DD}T{HH}:{mm}:{ss}Z, where
      YYYY -> four digits formatted year
      MM   -> two digits formatted month
      DD   -> two digits formatted day
      HH   -> 24-hours formatted hour
      mm   -> two digits formatted minutes
      ss   -> two digit formatted second

    Example: 2020-09-21T22:55:40Z

    @param {String} effectiveDateTime - EffectiveDateTime the user has entered.
  */
  setEffectiveDateTime,

  /*
    Sets a coupon code on the user's pricing context cookie. Note: This will not apply the coupon to
    the shopper's cart.

    @param {String} couponCode - Coupon code the user has entered.
  */
  setCouponCode: async function setCouponCode (c: any) : Promise<SetCouponResponse> {
    if (typeof c === 'string' || c instanceof String) {
      const couponCode = c.toString()
      const couponApplicationInformation = await setCoupon({ couponCode }) // This fires an event
      return couponApplicationInformation
    } else {
      throw new Error("Must pass a string for parameter 'couponCode'")
    }
  },

  /*
    Gets the current coupon code on the user's pricing context cookie.
  */
  getCouponCode: function getCouponCode () {
    const context = getCurrentContext()
    return context.couponCode
  },

  /*
    Sets current vat inclusive state on the user's pricing context cookie.

    @param {Boolean} isInclusive - Whether the current page state is VAT inclusive.
  */
  setVatInclusive: function setVatInclusive (isInclusive: any) {
    let updatedVatInclusivity : boolean

    if (typeof isInclusive === 'boolean' || isInclusive instanceof Boolean) {
      updatedVatInclusivity = !!isInclusive
    } else if (isInclusive !== undefined && isInclusive !== '' && (isInclusive.toUpperCase() === 'TRUE' || isInclusive.toUpperCase() === 'FALSE')) {
      updatedVatInclusivity = isInclusive.toUpperCase() === 'TRUE'
    } else {
      throw new Error("Must pass a boolean for parameter 'isInclusive'")
    }

    setVatInclusivity(updatedVatInclusivity) // this fires an event
  },

  /*
    Returns whether the user's pricing context is currently VAT inclusive.
  */
  isVatInclusive: function isVatInclusive () {
    const context = getCurrentContext()
    return context.vatInclusive
  },

  /*
    Returns whether the default user experience for a given market and merchant is tax inclusive.
  */
  isDefaultTaxInclusive,

  /*
    Clears any context pieces which should not persist post-order
  */
  orderPlaced: async function orderPlaced () {
    clearDataOnOrderPlaced()
    await setCoupon({})
  },

  /*
    Returns whether the user's pricing context is currently initialized
  */
  isInitialized,

  /*
    Initializes the pricing context based on the current page's context.

    @param {String} merchantId - Pricing Merchant Id of the current page, ie. 'Vistaprint-fashion-masks'
    @param {String} market - Pricing Market of the current page in the format '{ISO 3166 Alpha 2}'
    @param {Boolean} isDevelopmentMode - Should be true if running in a non-production environment.

    @return {object} The current pricing context object.
  */
  init,

  /*
    Resets the pricing context module cookie
  */
  reset,
}

/* --- Module Definition --- */
if (isValidShopperInfo(getShopperInfo())) {
  backgroundRefresh()
} else {
  window.addEventListener('userIdentity', function refresh () {
    window.removeEventListener('userIdentity', refresh)
    backgroundRefresh()
  })
}

declare global {
  interface Document {
    pcxtV3: typeof contextModule
  }
}
// Add the module to the page
document.pcxtV3 = contextModule

// Tell the page that the the module was loaded
fireEvent(SCRIPT_LOADED_EVENT, { version: contextModule.version })

export default contextModule
