import { fetchWithTimeout } from '../timeout'

export const isDefaultTaxInclusive = async (pricingMarket, merchantId) => {
  if (pricingMarket === undefined || merchantId === undefined) {
    return undefined
  }

  const sanitizedMerchantId = merchantId.toUpperCase()
  const sanitizedMarket = pricingMarket.toUpperCase()

  if (sanitizedMerchantId === 'VISTAPRINT' && sanitizedMarket === 'FR') {
    try {
      if (window && window.location && window.location.host && window.location.pathname && window.location.host.includes('www.vistaprint.')) {
        const encodedPathName = encodeURIComponent(window.location.pathname)

        const url = `/static/merch/default-tax-inclusivity.prod/v1/${sanitizedMerchantId}/${sanitizedMarket}/${encodedPathName}`

        const response = await fetchWithTimeout(url, {
          timeout: 500,
        })

        const result = await response.json()
        if (result.included === true) {
          return true
        }
      }
    } catch (e) {
      console.log('error fetching specific default tax inclusivity', e)
      // no-op
    }

    return false
  }

  if (sanitizedMarket === 'SG' && new Date().toISOString() >= '2023-04-03T14:00:00.000Z') {
    return true
  }

  if (sanitizedMerchantId === 'VISTAPRINT' ||
        sanitizedMerchantId === 'VISTAPRINT-FASHION-MASKS' ||
        sanitizedMerchantId === 'VISTAPRINT-PAYPAL') {
    switch (sanitizedMarket) {
      case 'US':
      case 'CA':
      case 'NZ':
      case 'SG':
        return false
      default:
        return true
    }
  } else if (sanitizedMerchantId === 'PROMOTIQUE') {
    switch (sanitizedMarket) {
      case 'DE':
      case 'AU':
      case 'IN':
        return true
      default:
        return false
    }
  }
  return false
}

export const getVatInclusivity = async (cachedContext, newContext, queryParams) => {
  return queryParams.vatInclusive !== undefined
    ? queryParams.vatInclusive
    : cachedContext.vatInclusive !== undefined
      ? cachedContext.vatInclusive
      : newContext.vatInclusive === undefined
        ? isDefaultTaxInclusive(newContext.market, newContext.merchantId)
        : newContext.vatInclusive
}
