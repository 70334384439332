import { EVENTS } from './events'

export const executeAfterPricingContextEvent = (event, callback, continueListening) => {
  if (typeof callback !== 'function') { return }

  if (Object.values(EVENTS).indexOf(event) === -1) {
    console.warn(`Event '${event}' is NOT a valid pricing context event.`)
    return
  }

  if (!windowAndDocumentExist()) { return }

  window.document.addEventListener(event, function onEvent (e) {
    if (!continueListening) {
      window.document.removeEventListener(event, onEvent, false)
    }
    callback(e.detail)
  })
}

export const getPcxtV3 = () => {
  if (!windowAndDocumentExist()) { return undefined }

  return window.document.pcxtV3
}

const windowAndDocumentExist = () => {
  return (typeof window !== 'undefined' && window.document)
}

export const appendSubtenant = (queryString: string, subtenant: string): string => {
  if (subtenant.length === 0) {
    console.error(`Invalid subtenant value ${subtenant.toString()}`)
    return queryString
  }

  return queryString + `&subtenant=${subtenant}`
}

export const isSubtenantMandatory = (merchantId: string | String): boolean => {
  const merchantsWithSubtenancy = ['VCS']

  return merchantsWithSubtenancy.some((merchantWithSubtenancy) => merchantWithSubtenancy === merchantId.toUpperCase())
}
