import { encodeContextObject, getPersistedContext, setCookieContext, resetCookieContext } from './cookie'
import { addCtcReaderTreatments } from './ctc'
import { getNMinutesFromNowUTC, getNowUTC } from './datetime'
import { VAT_INCLUSIVITY_QUERY_PARAMETER, INITIALIZED_EVENT, DATE_TIME_QUERY_PARAMETER, VAT_INCLUSIVITY_CHANGE_EVENT, CONTEXT_CHANGE_EVENT, DATE_TIME_CHANGE_EVENT, COUPON_CHANGE_EVENT } from '../shared/constants'
import { fireEvent } from './events'
import { getShopperInfo, isValidShopperInfo } from './shopper'
import { getVatInclusivity } from './vat'
import { PricingContext } from '../shared/types'
import { applyCouponRemotely, getRemoteContext } from './pricing-context-service'
import { isSubtenantMandatory } from './helpers'

/*
    Get the fields that should be excluded from the context when sent as query string or encoded string
*/
const getContextStringBlacklist = function () {
  return [
    'version',
    'vatInclusive',
    'expiresAt',
    'developmentMode',
    'shopperId',
  ]
}

const getEmptyContext = () : PricingContext => {
  return {
    version: 0,
    merchantId: undefined,
    market: undefined,
    couponCode: undefined,
    effectiveDateTime: undefined,
    customerGroups: undefined,
    vatInclusive: undefined,
    expiresAt: undefined,
    developmentMode: undefined,
    shopperId: undefined,
    subtenant: undefined,
  }
}

/*
    Returns current context or the default for the current site.
  */
export const getCurrentContext = (filterOutNullAndEmpties = false, filterOutBlacklist = false) : PricingContext => {
  const persistedContext = getPersistedContext()

  // If nothing is in the current cookie, set and return default.
  if (persistedContext === null) {
    throw new Error('Pricing context module not initialized.')
  }

  let context = persistedContext
  // Return decoded context, otherwise set and return default.
  try {
    if (filterOutNullAndEmpties) {
      const contextAsAny = context as any
      const filteredContext = Object.keys(contextAsAny)
        .filter(k => contextAsAny[k] !== undefined)
        .filter(k => contextAsAny[k] !== '')

        .reduce((filtered, field) => {
          filtered[field] = contextAsAny[field]
          return filtered
        }, { version: 0 } as any)

      context = filteredContext
    }

    if (filterOutBlacklist) {
      const contextAsAny = context as any

      const blacklist = getContextStringBlacklist()

      const filteredContext = Object.keys(contextAsAny)
        .filter(key => !blacklist.includes(key))

        .reduce((filtered, field) => {
          filtered[field] = contextAsAny[field]
          return filtered
        }, { version: 0 } as any)

      context = filteredContext
    }

    context.customerGroups = addCtcReaderTreatments(persistedContext.customerGroups)

    return context
  } catch (ex) {
    throw new Error('Failed to decode pricing context string.')
  }
}

/*
    Get the encoded pricing context object for use as a single parameter
  */
export const getContextString = function () {
  return encodeContextObject(getCurrentContext(true, true))
}

export const clearDataOnOrderPlaced = function () {
  const cachedContext = getCurrentContext()

  cachedContext.couponCode = undefined
  cachedContext.expiresAt = getNMinutesFromNowUTC(5)

  setCookieContext(cachedContext)
}

/*
    Returns current context or the default for the current site.
  */
export const getContextQueryString = (encodeUri: boolean = false): string => {
  const context = getCurrentContext(true, true)

  const definedContextFields = Object.keys(context)

  const contextAsAny = context as any
  let queryString = definedContextFields
    .filter(key => contextAsAny[key] !== undefined)
    .filter(key => !Array.isArray(contextAsAny[key]))
    .map(key => `${key}=${contextAsAny[key]}`)
    .join('&')

  const arrayFieldKeys = definedContextFields
    .filter(key => contextAsAny[key] !== undefined)
    .filter(key => Array.isArray(contextAsAny[key]))

  for (let i = 0; i < arrayFieldKeys.length; i += 1) {
    const arrayFieldKey = arrayFieldKeys[i]
    const arrayFieldQueryString = contextAsAny[arrayFieldKey].map((val: string) => `${arrayFieldKey}=${val}`).join('&')
    queryString += `&${arrayFieldQueryString}`
  }

  return encodeUri ? encodeURI(queryString) : queryString
}

const couponWasApplied = function (context: PricingContext, couponCode?: string) {
  if (!context.couponCode) {
    return !couponCode
  }

  return context.couponCode.toUpperCase() === couponCode?.toUpperCase()
}

export type SetCouponResponse = {
  couponApplied: boolean
  validationErrors: string[]
  couponInfoLinks: Record<string, string>
  currentPricingContext: PricingContext
}

export const setCoupon = async function ({ couponCode }: { couponCode?: string }) {
  const context = getCurrentContext()

  // Verify whether there is a change being processed
  if (!context.couponCode || context.couponCode.toUpperCase() !== couponCode?.toUpperCase()) {
    try {
      const shopperInfo = getShopperInfo()

      if (!isValidShopperInfo(shopperInfo)) {
        throw new Error('Missing shopper info')
      }

      const request = {
        merchantId: context.merchantId,
        market: context.market,
        shopperInfo,
        couponCode,
        developmentMode: !!context.developmentMode,
        subtenant: context.subtenant
      }
      const couponAppliedResponse = await applyCouponRemotely(request)

      const nextContext = {
        ...couponAppliedResponse.currentPricingContext,
        vatInclusive: context.vatInclusive,
        shopperId: shopperInfo.shopperId,
      }

      setCookieContext(nextContext)

      fireEvent(COUPON_CHANGE_EVENT, nextContext)
      fireEvent(CONTEXT_CHANGE_EVENT, nextContext)

      return {
        couponApplied: couponWasApplied(nextContext, couponCode),
        validationErrors: couponAppliedResponse.validationErrors || [],
        couponInfoLinks: couponAppliedResponse.couponInfoLinks || {},
        currentPricingContext: nextContext,
      }
    } catch (err) {
      console.info('Retaining existing context')
    }
  }

  return {
    couponApplied: couponWasApplied(context, couponCode),
    validationErrors: ['UnableToValidate'],
    couponInfoLinks: {},
    currentPricingContext: context,
  }
}

export const setEffectiveDateTime = async function (e: any) {
  if (typeof e !== 'string' && !(e instanceof String)) {
    throw new Error("Must pass a string for parameter 'effectiveDateTime'")
  }

  const effectiveDateTime = e.toString()
  const cachedContext = getCurrentContext()

  if (effectiveDateTime === cachedContext.effectiveDateTime) {
    return
  }

  try {
    const shopperInfo = getShopperInfo()

    if (!isValidShopperInfo(shopperInfo)) {
      throw new Error('Missing shopper info to call for remote context')
    }

    const remoteContext = await getRemoteContext(
      {
        merchantId: cachedContext.merchantId,
        market: cachedContext.market,
        shopperInfo,
        effectiveDateTime,
        developmentMode: !!cachedContext.developmentMode,
        subtenant: cachedContext.subtenant
      }
    )

    const nextContext = {
      ...remoteContext,
      vatInclusive: cachedContext.vatInclusive,
      shopperId: shopperInfo.shopperId,
    }

    setCookieContext(nextContext)
    fireEvent(CONTEXT_CHANGE_EVENT, nextContext)

    if (nextContext.effectiveDateTime && nextContext.effectiveDateTime !== cachedContext.effectiveDateTime) {
      fireEvent(DATE_TIME_CHANGE_EVENT, nextContext)
    }
  } catch (error) {
    console.info('Retaining existing context')
  }
}

export const setVatInclusivity = function (vatInclusive: boolean) {
  const context = getCurrentContext()

  context.vatInclusive = vatInclusive
  setCookieContext(context)

  // Fire event, with with payload of current (updated) context
  fireEvent(VAT_INCLUSIVITY_CHANGE_EVENT, context)
  fireEvent(CONTEXT_CHANGE_EVENT, context)
}

/*
    Scrap query params from URL and return vatInclusive and/or effectiveDateTime, if present as query string params
*/
export type PricingContextUrlParams = {
  vatInclusive?: boolean
  effectiveDateTime?: string
}
const scrapeURLParams = function () {
  const queryParams: PricingContextUrlParams = {}

  if (typeof window === 'undefined' || typeof window.location === 'undefined' || !window.location.search) {
    return queryParams
  }

  const urlParams = new URLSearchParams(window.location.search.toUpperCase())

  const vatInclusivityQueryParamString = urlParams.get(VAT_INCLUSIVITY_QUERY_PARAMETER)
  if (vatInclusivityQueryParamString) {
    queryParams.vatInclusive = vatInclusivityQueryParamString === 'TRUE'
  }

  const dateTimeQueryParamString = urlParams.get(DATE_TIME_QUERY_PARAMETER)
  if (dateTimeQueryParamString) {
    queryParams.effectiveDateTime = dateTimeQueryParamString
  }

  return queryParams
}

/*
    Retrieves the server-side pricing context using the latest cached context.
  */
export const backgroundRefresh = async () => {
  const queryParams = scrapeURLParams()

  let initialCachedContext: PricingContext
  try {
    initialCachedContext = getCurrentContext()
  } catch (ex) {
    return
  }

  const shopperInfo = getShopperInfo()

  const shopperId = shopperInfo && shopperInfo.shopperId ? shopperInfo.shopperId : ''

  if (!isValidShopperInfo(shopperInfo) ||
        initialCachedContext === undefined ||
        initialCachedContext.merchantId === undefined ||
        initialCachedContext.market === undefined ||
        (isSubtenantMandatory(initialCachedContext.merchantId) && initialCachedContext.subtenant === undefined)) {
    return
  }

  let newContext: PricingContext
  try {
    newContext = await getRemoteContext(
      {
        merchantId: initialCachedContext.merchantId,
        market: initialCachedContext.market,
        shopperInfo,
        effectiveDateTime: queryParams.effectiveDateTime,
        developmentMode: !!initialCachedContext.developmentMode,
        subtenant: initialCachedContext.subtenant
      }
    )
  } catch (error) {
    return
  }

  newContext.customerGroups = addCtcReaderTreatments(newContext.customerGroups)

  newContext.vatInclusive = await getVatInclusivity(initialCachedContext, newContext, queryParams)

  newContext.shopperId = shopperId

  let latestCachedContext
  try {
    latestCachedContext = getCurrentContext()
  } catch (ex) {
    return
  }

  // Check the latest context before persisting the context to avoid race conditions with
  // client-initiated initialization
  if (initialCachedContext.merchantId !== latestCachedContext.merchantId ||
      initialCachedContext.market !== latestCachedContext.market) {
    return
  }

  setCookieContext(newContext)
  fireEvent(CONTEXT_CHANGE_EVENT, newContext)
}

export const isInitialized = (merchantId?: string, market?: string, isDevelopmentMode?: boolean, subtenant?: string) => {
  const shopperInfo = getShopperInfo()

  const queryParams = scrapeURLParams()

  let cachedContext: PricingContext
  try {
    cachedContext = getCurrentContext()
  } catch (ex) {
    cachedContext = getEmptyContext()
  }

  if (merchantId && market && isDevelopmentMode !== undefined) {
    const v3ContextInitialized =
          cachedContext.shopperId !== undefined && // You have (A) a context from a previous version - or - (B) a context from a session where auth failed to load
          (!shopperInfo.shopperId || shopperInfo.shopperId === cachedContext.shopperId) && // Check cached shopperId against current shopperId, if available
          cachedContext.developmentMode === isDevelopmentMode && // You are in the wrong env
          cachedContext.expiresAt !== undefined && // You have a context from a previous version
          cachedContext.expiresAt.toISOString() > (getNowUTC()) && // You have an expired context
          cachedContext.merchantId === merchantId.toUpperCase() && // You have a context from the wrong vendor's website
          cachedContext.market === market.toUpperCase() && // You have a context from the wrong website for the vendor
          (!queryParams.effectiveDateTime || cachedContext.effectiveDateTime === queryParams.effectiveDateTime) && // You are attempting to re-init from a session with a different effectivedatetime
          (!isSubtenantMandatory(cachedContext.merchantId) || cachedContext.subtenant === subtenant) // You have a context from the wrong subtenant for the vendor

    return v3ContextInitialized
  }

  // If any of the fields filled in by initialization are undefined, return false
  return !(
    cachedContext.merchantId === undefined ||
      cachedContext.market === undefined ||
      cachedContext.vatInclusive === undefined ||
      (isSubtenantMandatory(cachedContext.merchantId) && cachedContext.subtenant === undefined)
  )
}

export async function init (merchantId: string | String, market: string | String, isDevelopmentMode = false, subtenant?: string | String) {
  if (!(typeof merchantId === 'string' || merchantId instanceof String) || merchantId === '' || merchantId.toLowerCase() === 'undefined') {
    throw new Error("Must pass a non-empty, defined string for parameter 'merchantId'")
  }
  const merchantIdAsString = merchantId.toString()

  if (!(typeof market === 'string' || market instanceof String) || market === '' || market.toLowerCase() === 'undefined') {
    throw new Error("Must pass a non-empty, defined string for parameter 'market'")
  }
  const marketAsString = market.toString()

  if (isSubtenantMandatory(merchantId) &&
    (!(typeof subtenant === 'string' || subtenant instanceof String) || subtenant === '' || subtenant.toLowerCase() === 'undefined')) {
    throw new Error(`Must pass a non-empty, defined string for parameter 'subtenant' for merchantId ${merchantId}`)
  }
  const subtenantAsString = subtenant?.toString()

  const queryParams = scrapeURLParams()

  let cachedContext: PricingContext
  try {
    cachedContext = getCurrentContext()
  } catch (ex) {
    cachedContext = getEmptyContext()
  }

  const shopperInfo = getShopperInfo()

  const shopperId = shopperInfo && shopperInfo.shopperId ? shopperInfo.shopperId : ''

  const contextDataNeedsRefresh =
      cachedContext.developmentMode !== isDevelopmentMode || // You are in the wrong env
      cachedContext.expiresAt === undefined || // You have a context from a previous version
      cachedContext.expiresAt.toISOString() <= (getNowUTC()) || // You have an expired context
      cachedContext.merchantId !== merchantIdAsString.toUpperCase() || // You have a context from the wrong vendor's website
      cachedContext.market !== marketAsString.toUpperCase() || // You have a context from the wrong website for the vendor
      (queryParams.effectiveDateTime && cachedContext.effectiveDateTime !== queryParams.effectiveDateTime) || // You are attempting to re-init from a session with a different effectivedatetime
      (isSubtenantMandatory(merchantId) && cachedContext.subtenant !== subtenantAsString) // You have a context from the wrong subtenant for the vendor

  const contextOwnershipNeedsRefresh = shopperId !== '' && cachedContext.shopperId !== shopperId

  let newContext: PricingContext
  if (contextDataNeedsRefresh || contextOwnershipNeedsRefresh) {
    try {
      if (!isValidShopperInfo(shopperInfo)) {
        throw new Error('Missing shopper info to call for remote context')
      }

      newContext = await getRemoteContext(
        {
          merchantId: merchantIdAsString,
          market: marketAsString,
          shopperInfo,
          effectiveDateTime: queryParams.effectiveDateTime,
          developmentMode: isDevelopmentMode,
          subtenant: subtenantAsString,
        }
      )
    } catch (error) {
      newContext = cachedContext

      newContext.merchantId = merchantId.toUpperCase()
      newContext.market = market.toUpperCase()
      newContext.expiresAt = getNMinutesFromNowUTC(1)
      newContext.developmentMode = isDevelopmentMode
      newContext.subtenant = subtenantAsString
    }
  } else {
    newContext = cachedContext
  }

  newContext.customerGroups = addCtcReaderTreatments(newContext.customerGroups)

  newContext.vatInclusive = await getVatInclusivity(cachedContext, newContext, queryParams)

  newContext.shopperId = shopperId

  setCookieContext(newContext)

  // Fire event, pricing context initialized with payload of current context
  fireEvent(INITIALIZED_EVENT, newContext)

  return newContext
}

export const reset = () => {
  resetCookieContext()
}
