import { ShopperInfo } from '../shared/types'
import { fetchWithTimeout } from '../timeout'
import { validateEffectiveDateTimeFormat } from './datetime'
import { getPageBasedRequestor } from './http'
import { appendSubtenant } from './helpers'

// Where the remote service lives
const CONTEXT_SERVICE_BASE_URL = 'https://pricing-context-service.personalization.vpsvc.com'

type PricingContextServiceInput = {
  merchantId?: string,
  market?: string,
  shopperInfo?: ShopperInfo,
  effectiveDateTime?: string,
  developmentMode: boolean,
  couponCode?: string,
  subtenant?: string,
}

export const getRemoteContext = async function (request: PricingContextServiceInput) {
  if (!request.merchantId || !request.market || !request.shopperInfo) {
    throw new Error('Missing input for pricing context service')
  }

  const requestor = getPageBasedRequestor()

  const url = `${CONTEXT_SERVICE_BASE_URL}/v1/pricingContext/${request.merchantId}/${request.market}/${request.shopperInfo.shopperId}`
  let queryString = `?developmentMode=${request.developmentMode}&Requestor=${requestor}`
  if (request.subtenant) {
    queryString = appendSubtenant(queryString, request.subtenant)
  }
  queryString += request.effectiveDateTime
    ? validateEffectiveDateTimeFormat(request.effectiveDateTime)
      ? `&EffectiveDateTime=${request.effectiveDateTime}`
      : '&EffectiveDateTime=clear'
    : ''

  const response = await fetchWithTimeout(url + encodeURI(queryString), {
    timeout: 1500,
    headers: {
      'Content-Type': 'application/json',
      Authorization: request.shopperInfo.authorization || '',
    },
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Pricing Context Service returned ${response.status}`)
  }

  const responseJson = await response.json()

  return responseJson
}

export const applyCouponRemotely = async function (applyCouponRequest: PricingContextServiceInput) {
  if (!applyCouponRequest.merchantId || !applyCouponRequest.market || !applyCouponRequest.shopperInfo) {
    throw new Error('Missing input for pricing context service')
  }

  const requestor = getPageBasedRequestor()

  const url = `${CONTEXT_SERVICE_BASE_URL}/v1/pricingContext/${applyCouponRequest.merchantId}/${applyCouponRequest.market}/${applyCouponRequest.shopperInfo.shopperId}/applyCoupon`
  let queryString = `?developmentMode=${applyCouponRequest.developmentMode}&Requestor=${requestor}`

  if (applyCouponRequest.subtenant) {
    queryString = appendSubtenant(queryString, applyCouponRequest.subtenant)
  }

  const response = await fetchWithTimeout(url + encodeURI(queryString), {
    method: 'POST',
    body: JSON.stringify({ couponCode: applyCouponRequest.couponCode }),
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: applyCouponRequest.shopperInfo.authorization || '',
    },
  })

  if (response.status !== 422 && response.status >= 400 && response.status < 600) {
    throw new Error(`Pricing Context Service returned ${response.status}`)
  }

  const responseJson = await response.json()
  return responseJson
}
