import { COOKIE_NAME, COOKIE_REGEX } from '../shared/constants'
import { PricingContext } from '../shared/types'

// Keep an in-memory version of the cookie just in case `document.cookie` is inaccessible (e.g. during static page generation)
// or a shopper clears their cookies during their session.
let inMemoryCookie

// This will be used to set the domain on the cookie, so any subdomains (eg merch.vpsvc.com) will be included
const allowedDomains = ['vpsvc', 'vistaprint']

/*
    Gets the top-level domain to use when setting the cookie. E.g. gallery.vistaprint.com will return vistaprint.com.
  */
const domainForCookie = function () {
  if (typeof document === 'undefined' || typeof document.cookie === 'undefined' || !window.location.hostname) {
    return ''
  }

  const host = window.location.hostname

  for (let i = 0; i < allowedDomains.length; i += 1) {
    const domainIndex = host.lastIndexOf(allowedDomains[i])
    if (domainIndex >= 0) {
      return host.slice(domainIndex)
    }
  }

  return host
}

export const setCookieContext = function (context) {
  const now = new Date()
  const expireDate = new Date(now.getTime() + (1000 * 604800))
  const encodedContext = encodeContextObject(context)
  let cookieString = `${COOKIE_NAME}=${encodedContext};expires=${expireDate.toUTCString()};SameSite=Strict;path=/`

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const domain = domainForCookie()
    cookieString += `;domain=${domain}`
    document.cookie = cookieString
  }

  inMemoryCookie = cookieString
}

export const resetCookieContext = function () {
  const now = new Date()
  const expireDate = new Date(now.getTime() + (1000 * 604800))
  const encodedContext = ''
  let cookieString = `${COOKIE_NAME}=${encodedContext};expires=${expireDate.toUTCString()};SameSite=Strict;path=/`

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const domain = domainForCookie()
    cookieString += `;domain=${domain}`
    document.cookie = cookieString
  }

  inMemoryCookie = cookieString
}

export const getRawCookieValue = () : string | null => {
  const cookieString = typeof document !== 'undefined' &&
                      typeof document.cookie !== 'undefined' &&
                      document.cookie !== '' &&
                      document.cookie.match(COOKIE_REGEX)
    ? document.cookie
    : inMemoryCookie

  try {
    const match = cookieString.match(COOKIE_REGEX)
    if (!match) {
      return null
    }
    // The regex match should return an array of length 2. The first value
    // is the 'PCXT=' and the second is the encoded context string value.
    return match[2]
  } catch (ex) {
    return null
  }
}

/*
    Returns a url-friendly context string that represents the context object.
  */
export const encodeContextObject = function (contextObj) {
  const base64 = btoa(JSON.stringify(contextObj))
  // Replace chars in the base64 string to make it url safe per the RFC spec
  // https://tools.ietf.org/html/rfc4648#page-7
  return base64.replace(/\+/g, '-').replace(/=/g, '').replace(/\//g, '_')
}

/*
    Returns the context object that the context string represents.
  */
const decodeContextString = function (contextString) {
  const replacedChars = contextString.replace(/-/g, '+').replace(/_/g, '/')
  const decoded = JSON.parse(atob(replacedChars)) as PricingContext
  if (typeof decoded.expiresAt === 'string') {
    decoded.expiresAt = new Date(decoded.expiresAt)
  }

  return decoded
}

export const getPersistedContext = () : PricingContext | null => {
  const contextString = getRawCookieValue()
  if (!contextString) {
    return null
  }

  try {
    return decodeContextString(contextString)
  } catch (ex) {
    console.error('Error decoding context string', ex)
    return null
  }
}
