import ctcReader from '@vp/ctc-reader'

/*
    Adds all treatments (customer groups) from the browser-side CTC Reader module
    to a context object. This is needed to prevent a race condition where a shopper is put into
    a customer group on page initialization but has not been persisted to the server-side
    treatment service.
  */
export const addCtcReaderTreatments = function (customerGroups) {
  try {
    const treatments = ctcReader.getAllTreatments()
    if (customerGroups) {
      return [
        ...new Set([...customerGroups, ...treatments]),
      ]
    } else {
      return treatments
    }
  } catch (e) {
    console.log(`treatment initialization failed on: ${e.message}`)
  }
  return customerGroups
}
